<template>
  <section class="h-100">

    <div class="m-auto text-center mb-7">
      <img
        src="@/assets/images/menu_2.svg"
        class="w-full"
      >

      <img
        src="@/assets/images/setup_profile/step_2.svg"
        class="mx-auto mt-5"
      >
      <span class="text-neutral">Step 2 of 10</span>
    </div>

    <div class="flex h-screen text-center" style="height: 80vh;">

      <div class="m-auto pb-10 w-2/3">

        <div class="mb-12 relative">
          <span>This will take approximately 18 minutes to complete</span>
        </div>

        <div class="mt-9">
          <h3 class="text text-center text-black mb-4">
            About {{ firstName }}
          </h3>

          <a href="#" class="text-primary font-bold">Why do you need all this data?</a>
        </div>

        <form
          class="mt-10 mb-20"
          @submit.prevent="register"
        >

          <div
            v-if="errorMessage"
            class="flex items-center text-dark text-sm mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-10">
            <input
              v-model="words"
              type="text"
              class="form-control w-1/2"
              placeholder="5 words that best describe you"
              required
            >
          </div>

          <div class="mb-6">
            <textarea
              v-model="description"
              rows="5"
              cols="10"
              class="w-1/2 p-2"
              placeholder="Tell us more about you."
            ></textarea>
          </div>
        </form>

        <div>
          <router-link
            :to="{name: 'v2.profile.basic'}"
            class="text-primary float-left"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left_blue.svg"
              class="inline"
            >
            Back
          </router-link>

          <router-link
            :to="{name: 'v2.profile.experience'}"
            class="btn btn-primary-outline align-self-center me-auto mr-5"
          >
            Finish Later
          </router-link>

          <router-link
            :to="{name: 'v2.profile.experience'}"
            class="btn btn-primary align-self-center me-auto"
          >
            Onwards
          </router-link>

          <router-link
            :to="{name: 'v2.profile.experience'}"
            class="text-primary float-right"
          >
            Skip

            <img
              src="@/assets/images/icons/ic_chevron_right_blue.svg"
              class="inline"
            >
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      firstName: '',
      words: '',
      description: '',
      errorMessage: null
    };
  },
  methods: {
  },
  mounted() {
    let firstName = localStorage.getItem('preferredname');

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
